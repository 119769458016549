import { PublicCompanyData } from '@/store/modules/company';

import { InvoiceItemType } from '@/helpers/api/companyEnviorment/InvoiceHelper';
import { SepaApiData } from '@/helpers/api/companyEnviorment/SepaHelper';

import { ActivityApiData } from './Activities';
import { Agreement, AgreementApiData } from './Agreement';
import { HasMedia, MediaItem } from './Media';
import { HasNotes, Note, Noteable, NoteableModel } from './Noteable';
import { PropertyApiSingleData, PropertySingle } from './Property';
import { Space, SpaceApiData } from './Space';
import { Transaction } from './Transaction';

export enum InvoiceType {
  DEBIT = 'debit',
  CREDIT = 'credit'
}

export enum InvoicePeriod {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly'
}

export type InvoiceProduct = {
  id?: number;
  name: string;
  discount_name: string;
  price: number | null;
  vat: number;
  quantity: number;
  type: InvoiceItemType;
  discount_percentage: number;
  discount: number;
  logistical_item_id: string;
  discount_price: number;
  remaining_price: number;
  debit_price: number;
  credited: {
    amount: number;
    quantity: number;
    discount: number;
  };
};

export type InvoiceStatus = 'created' | 'fully_paid' | 'partial_paid' | 'expired' | 'uncollectible';

export type InvoiceDetails = {
  house_number: string;
  house_number_addition: string;
  name: string;
  company_name: string;
  postcode: string;
  street: string;
  town: string;
  description: string;
  extra_invoice_fields?: {
    key: string;
    value: string;
  }[];
  kvk_number: string;
  btw_number: string;
};

export const PAYMENT_METHOD_TYPE_MANUAL = 'manual';
export type PaymentMethodTypeManual = typeof PAYMENT_METHOD_TYPE_MANUAL;
export const PAYMENT_METHOD_TYPE_OPP = 'online_payment_platform';
export type PaymentMethodTypeOPP = typeof PAYMENT_METHOD_TYPE_OPP;
export const PAYMENT_METHOD_TYPE_SEPA = 'sepa';
export type PaymentMethodTypeSepa = typeof PAYMENT_METHOD_TYPE_SEPA;
export type PaymentMethodType = PaymentMethodTypeOPP | PaymentMethodTypeManual | PaymentMethodTypeSepa;

export class Invoice implements Noteable, HasMedia {
  payment_method: PaymentMethodType;
  note_type: NoteableModel;
  notes: Note[];
  id: number;
  uuid: string;
  rentable_type: string;
  rentable_id: number;
  products: InvoiceProduct[];
  total: number;
  subtotal: number;
  vat: number;
  vat_amount: number;
  number: string;
  status: InvoiceStatus;
  invoice_date: Date;
  created_at: Date;
  expiration_date: Date;
  agreement?: Agreement;
  transactions: Transaction[];
  details?: InvoiceDetails & { full_address: string };
  activities: ActivityApiData[];
  private _property: PropertySingle | undefined;
  private _space: Space | undefined;
  media: MediaItem[];
  invoices: Partial<InvoiceApiData[]>;
  debit_invoice?: (InvoiceApiData | undefined)[][];
  type: InvoiceType.DEBIT | InvoiceType.CREDIT;
  invoice_period: InvoicePeriod.MONTHLY | InvoicePeriod.QUARTERLY | InvoicePeriod.YEARLY;
  invoice_period_starts_at: Date;
  invoice_period_ends_at: Date;
  recurring: boolean;
  sepaBatch: SepaApiData;
  attachments: MediaItem[];
  amount_due: number;

  get property() {
    return this._property ?? this._space?.property;
  }

  get space() {
    return this._space;
  }

  constructor(params: InvoiceApiData) {
    this.note_type = 'invoice';
    this.id = params.id;
    this.uuid = params.uuid;
    // eslint-disable @typescript-eslint/naming-convention
    this.rentable_type = params.rentable_type;
    // eslint-disable @typescript-eslint/naming-convention
    this.rentable_id = params.rentable_id;
    this.products = params.line_items;
    this.total = params.total;
    this.subtotal = params.subtotal;
    this.vat = params.vat ?? 0;
    // eslint-disable @typescript-eslint/naming-convention
    this.vat_amount = params.vat_amount;
    this.number = params.number;
    this.status = params.status;
    // eslint-disable @typescript-eslint/naming-convention
    this.invoice_date = new Date(params.invoice_date);
    // eslint-disable @typescript-eslint/naming-convention
    this.expiration_date = new Date(params.expiration_date);
    // eslint-disable @typescript-eslint/naming-convention
    this.created_at = new Date(params.created_at);
    this.transactions = params.transactions ?? [];
    this.notes = params.notes ?? [];
    this.activities = params.activities ?? [];
    this.payment_method = params.payment_method;
    this.media = params.media ?? [];
    this.type = params.type;
    this.invoices = params.invoices ?? [];
    this.debit_invoice = params.debit_invoice ? [params.debit_invoice] : [];
    this.invoice_period = params.invoice_period;
    this.invoice_period_starts_at = new Date(params.invoice_period_starts_at);
    this.invoice_period_ends_at = new Date(params.invoice_period_ends_at);
    this.amount_due = params.amount_due;

    const { details, property, space, agreement } = params;
    const full_address = (details?.street ?? '') + ' ' + (details?.house_number ?? '') + (details?.house_number_addition ?? '');
    this.details = details
      ? {
          ...details,
          // eslint-disable @typescript-eslint/naming-convention
          full_address
        }
      : undefined;
    this._property = property ? new PropertySingle(property) : undefined;
    this._space = space ? new Space(space) : undefined;
    this.agreement = agreement ? new Agreement(agreement) : undefined;
    this.recurring = params.recurring;
    this.sepaBatch = params.sepaBatch;
    this.attachments = params.attachments;
  }
}

export type InvoiceApiData = HasNotes & {
  id: number;
  uuid: string;
  rentable_type: string;
  rentable_id: number;
  line_items: InvoiceProduct[];
  total: number;
  subtotal: number;
  vat: number | null;
  vat_amount: number;
  number: string;
  status: InvoiceStatus;
  invoice_date: string;
  expiration_date: string;
  created_at: string;
  agreement: AgreementApiData;
  transactions?: Transaction[];
  details?: InvoiceDetails;
  property?: PropertyApiSingleData;
  space?: SpaceApiData;
  activities?: ActivityApiData[];
  payment_method: PaymentMethodType;
  media?: MediaItem[];
  invoices: Partial<InvoiceApiData[]>;
  debit_invoice: Partial<InvoiceApiData[]>;
  type: InvoiceType.DEBIT | InvoiceType.CREDIT;
  invoice_period: InvoicePeriod.MONTHLY | InvoicePeriod.QUARTERLY | InvoicePeriod.YEARLY;
  invoice_period_starts_at: string;
  invoice_period_ends_at: string;
  recurring: boolean;
  sepaBatch: SepaApiData;
  attachments: MediaItem[];
  amount_due: number;
};

export type InvoicePluralApiData = {
  id: number;
  uuid: string;
  status: InvoiceStatus;
  quantity: number;
  total: number;
  amount_due: number;
  created_at: string;
  invoice_date: string;
  expiration_date: string;
  number: number | string;
  details: InvoiceDetails;
  company?: PublicCompanyData;
  agreement?: AgreementApiData;
  vat?: number | null;
  type: InvoiceType.DEBIT | InvoiceType.CREDIT;
  payment_method?: PaymentMethodType;
};

export class InvoicePlural {
  id: number;
  uuid: string;
  status: InvoiceStatus;
  total: number;
  amount_due: number;
  quantity: number;
  created_at: Date;
  invoice_date: Date;
  expiration_date: Date;
  number: number | string;
  details: InvoiceDetails;
  company?: PublicCompanyData;
  agreement?: AgreementApiData;
  payment_method?: PaymentMethodType;
  vat: number;
  type: InvoiceType.DEBIT | InvoiceType.CREDIT;

  get name(): string {
    return this.details.name;
  }

  get invoiceNumber(): string {
    // hack: check if number contains "-", if so, it's probably already correctly formatted
    if (this.number.toString().includes('-')) {
      return this.number.toString();
    }

    const year = this.created_at.getFullYear();
    const month = `${this.created_at.getMonth() + 1}`.padStart(2, '0');
    const number = this.number.toString().padStart(5, '0');
    return `${year}-${month}-${number}`;
  }

  constructor(params: InvoicePluralApiData) {
    this.id = params.id;
    this.uuid = params.uuid;
    this.status = params.status;
    this.total = params.total;
    this.amount_due = params.amount_due;
    this.quantity = params.quantity;
    // eslint-disable @typescript-eslint/naming-convention
    this.created_at = new Date(params.created_at);
    this.invoice_date = new Date(params.invoice_date);
    this.details = params.details;
    // eslint-disable @typescript-eslint/naming-convention
    this.expiration_date = new Date(params.expiration_date);
    this.payment_method = params.payment_method;
    this.number = params.number;
    this.company = params.company;
    this.agreement = params.agreement;
    this.vat = params.vat ?? 0;
    this.type = params.type;
  }
}

export type StoreInvoiceForm = {
  //
};

export type UpdateInvoiceForm = StoreInvoiceForm;

export default Invoice;
