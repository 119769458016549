import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: 'dashboard',
    name: 'management.dashboard',
    component: () => import('@/views/management/Dashboard.vue')
  },

  {
    path: 'clients',
    name: 'management.company.index',
    component: () => import('@/views/management/company/Index.vue')
  },
  {
    path: 'client/:id',
    name: 'management.company.show',
    component: () => import('@/views/management/company/Show.vue'),
    props: true
  },

  {
    path: 'properties',
    name: 'management.property.index',
    component: () => import('@/views/management/property/Index.vue')
  },

  {
    path: 'team',
    component: () => import('@/views/management/team/Wrapper.vue'),
    children: [
      {
        path: '',
        name: 'management.team.index',
        component: () => import('@/views/management/team/Index.vue')
      },
      {
        path: 'invites',
        name: 'management.team.invite.index',
        component: () => import('@/views/management/team/invite/Index.vue')
      }
    ]
  },

  {
    path: 'finances/invoices',
    name: 'management.finances.invoice.index',
    component: () => import('@/views/management/financial/invoice/Index.vue')
  },
  {
    path: 'finances/billing',
    name: 'management.finances.billing.index',
    component: () => import('@/views/management/financial/billing/Index.vue')
  },
  {
    path: 'finances/billing/:companyId/:date(\\d{4}-\\d{2})',
    name: 'management.finances.billing.show',
    component: () => import('@/views/management/financial/billing/Show.vue'),
    props: true
  },
  {
    path: 'finances/payouts',
    name: 'management.finances.payouts.index',
    component: () => import('@/views/management/financial/payouts/Index.vue')
  },
  {
    path: 'finances/purchases',
    name: 'management.finances.purchase.index',
    component: () => import('@/views/management/financial/purchase/Index.vue')
  },

  {
    path: 'export',
    name: 'management.export',
    component: () => import('@/views/management/export/Export.vue')
  },

  {
    path: 'finances/invoices',
    name: 'management.finances.invoice.index',
    component: () => import('@/views/management/financial/invoice/Index.vue')
  },
  {
    path: 'finances/billing',
    name: 'management.finances.billing.index',
    component: () => import('@/views/management/financial/billing/Index.vue')
  },
  {
    path: 'finances/billing/:companyId/:date(\\d{4}-\\d{2})',
    name: 'management.finances.billing.show',
    component: () => import('@/views/management/financial/billing/Show.vue'),
    props: true
  },

  {
    path: 'maintenance/tickets',
    name: 'management.repair.index',
    component: () => import('@/views/management/maintenance/Tickets.vue')
  },
  {
    path: 'maintenance/closed',
    name: 'management.repair.closed',
    component: () => import('@/views/management/maintenance/Archived.vue')
  },
  {
    path: 'maintenance/ticket/:id',
    name: 'management.repair.show',
    component: () => import('@/components/repair/ReportAssignmentShow.vue'),
    props: true
  },
  {
    path: 'calendar',
    name: 'management.repair.calendar',
    component: () => import('@/views/management/maintenance/Calendar.vue')
  },

  {
    path: 'notifications',
    name: 'management.notifications',
    component: () => import('@/views/common/Notifications.vue')
  },
  {
    path: 'account',
    name: 'management.account',
    component: () => import('@/views/common/Account.vue')
  },
  {
    path: 'company/switch',
    name: 'management.switch',
    component: () => import('@/views/CompanySwitcher.vue')
  },
  {
    path: 'search',
    name: 'management.search',
    component: () => import('@/views/management/Search.vue')
  },
  {
    path: 'settings',
    component: () => import('@/views/management/settings/Wrapper.vue'),
    children: [
      {
        path: '',
        name: 'management.settings',
        component: () => import('@/views/management/settings/General.vue')
      },
      {
        path: 'finances',
        name: 'management.settings.finances',
        component: () => import('@/views/common/settings/Finances.vue')
      },
      {
        path: 'subscriptions',
        name: 'management.settings.subscriptions',
        component: () => import('@/views/management/settings/Subscriptions.vue')
      },
      {
        path: 'installations',
        name: 'management.settings.installations',
        component: () => import('@/views/common/settings/Installations.vue')
      },
      {
        path: 'branding',
        name: 'management.settings.branding',
        component: () => import('@/views/management/settings/Branding.vue')
      }
    ]
  },
  {
    path: ':pathMatch(.*)*',
    name: 'NotFoundAuth',
    component: () => import('@/views/NotFound.vue')
  }
];

export default routes;
